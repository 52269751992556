import { LockClosedIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { Tooltip } from '../Tooltip'

export function Lock({ message, stopClick }: { message?: string; stopClick?: boolean }) {
  return (
    <Tooltip
      className={classNames(stopClick ? `!pointer-events-none` : `pointer-events-auto`)}
      message={
        <div className="flex max-w-xs flex-col gap-2 p-1">
          <div className="flex items-center gap-2">
            <LockClosedIcon height={16} width={16} strokeWidth={2} className="text-ui-sm text-trn-100" />
            <p className="text-ui-sm text-trn-100">Locked Information</p>
          </div>
          <p className="whitespace-pre-wrap text-body-xs text-trn-200">
            {message ??
              `This content is unavailable because this contact is not associated with a location that has an active PMS integration`}
          </p>
        </div>
      }
    >
      <LockClosedIcon className={`ml-2 inline h-4 w-4`} />
    </Tooltip>
  )
}
